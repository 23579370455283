<template>
    <div class="flex-row align-items-center">
        <CRow class="justify-content-center headimg mb-5">
            <CCol md="12" class="p-0 ">
                <div style="background-image: url('img/Dashboard.png'); background-position: 50% 50%; background-size: cover; height: 200px;">
                    <div class="middle-center text-center text-white">
                        <h2 class=""><b>Laporan <span class="text-success">Realisasi</span></b></h2>
                    </div>
                </div>
            </CCol>
        </CRow>
        <div class="container">
            <CRow class="justify-content-center">
                <CCol md="12">
                    <CCard>
                        <CCardHeader class="top-radius-15 bg-success text-white">
                            <h3 class="mb-0 text-center">Detail Laporan Realisasi</h3>
                        </CCardHeader>
                        <CCardBody>
                            <table class="w-100" border="0">
                                <tr>
                                    <th style="width: 250px">Kode Permohonan</th>
                                    <td style="width: 15px"> : </td>
                                    <td>{{(detailDataRealisasi.kode_permohonan)?detailDataRealisasi.kode_permohonan:"Data Tidak Tersedia"}} </td>
                                </tr>
                                <tr>
                                    <th style="width: 250px">Nomor SIP </th>
                                    <td style="width: 15px"> : </td>
                                    <td>{{detailDataRealisasi.prs_nomor_sk}} </td>
                                </tr>
                                <tr>
                                    <th>Tanggal Pengajuan</th>
                                    <td style="width: 15px"> : </td>
                                    <td>{{detailDataRealisasi.prs_tanggal}} </td>
                                </tr>
                                <tr>
                                    <th>Perihal</th>
                                    <td style="width: 15px"> : </td>
                                    <td>{{detailDataRealisasi.prs_perihal}} </td>
                                </tr>
                                <tr>
                                    <th>Kategori Permohonan</th>
                                    <td style="width: 15px"> : </td>
                                    <td>{{detailDataRealisasi.prs_kategori}} </td>
                                </tr>
                                <tr v-if="detailDataRealisasi.permohonan.pr_kategori == 'Sumber Daya Genetik'">
                                    <th style="width: 250px">Jenis SDG/Komoditas </th>
                                    <td style="width: 15px"> : </td>
                                    <td>{{detailDataRealisasi.permohonan.sdg_komoditas.t_nama}}</td>
                                </tr>
                                <tr v-if="detailDataRealisasi.permohonan.pr_kategori == 'Sumber Daya Genetik'">
                                    <th style="width: 250px">Varietas/Klon/Hibrida </th>
                                    <td style="width: 15px"> : </td>
                                    <td>{{detailDataRealisasi.permohonan.sdg_varietas_klon_hibrida}}</td>
                                </tr>
                                <tr v-if="detailDataRealisasi.permohonan.pr_kategori == 'Sumber Daya Genetik'">
                                    <th style="width: 250px">Bentuk Benih </th>
                                    <td style="width: 15px"> : </td>
                                    <td>{{detailDataRealisasi.permohonan.sdg_bentuk_benih.bb_nama}}</td>
                                </tr>
                                <tr v-if="detailDataRealisasi.permohonan.pr_kategori == 'Sumber Daya Genetik'">
                                    <th style="width: 250px">Banyaknya SDG </th>
                                    <td style="width: 15px"> : </td>
                                    <td>{{detailDataRealisasi.permohonan.sdg_banyaknya}}</td>

                                </tr>
                                <tr v-if="detailDataRealisasi.permohonan.pr_kategori == 'Sumber Daya Genetik'">
                                    <th style="width: 250px">Satuan SDG </th>
                                    <td style="width: 15px"> : </td>
                                    <td>{{detailDataRealisasi.permohonan.sdg_satuan.s_nama}}</td>
                                </tr>
                                <tr v-if="detailDataRealisasi.permohonan.pr_kategori == 'Sumber Daya Genetik'">
                                    <th style="width: 250px">Kode HS </th>
                                    <td style="width: 15px"> : </td>
                                    <td>{{detailDataRealisasi.permohonan.sdg_kode_hs}}</td>
                                </tr>
                                <tr>
                                    <th>Keterangan</th>
                                    <td style="width: 15px"> : </td>
                                    <td>{{detailDataRealisasi.prs_keterangan}} </td>
                                </tr>
                                <tr>
                                    <th>Data Benih</th>
                                    <td style="width: 15px"> : </td>
                                    <td>Data Benih Untuk Realisasi</td>
                                </tr>
                <tr>
                  <th>Dokumen Laporan Realisasi</th>
                  <td style="width: 15px">:</td>
                  <td>
                    <template v-if="detailDataRealisasi.file_realisasi">
                      <div
                        target="_blank"
                        class="btn btn-xs btn-warning btn-hover-outline-warning text-nowrap"
                        v-on:click="
                          getFileWithLink(detailDataRealisasi.file_realisasi)
                        "
                      >
                        <i class="fa fa-eye"></i> Lihat
                      </div>
                    </template>
                    <template v-else>Tidak ada dokumen lap. realisasi</template>
                  </td>
                </tr>
                <tr>
                                    <td colspan="3">
                                        <table class="table table-bordered table-sm w-100" id="table-benih">
                                            <thead class="bg-sapphire">
                                                <tr>
                                                    <th style="width: 15px">No</th>
                                                    <th v-if="detailDataRealisasi.prs_kategori != 'Sumber Daya Genetik'">Jenis Tanaman</th>
                                                    <th v-if="detailDataRealisasi.prs_kategori != 'Sumber Daya Genetik'">Nama Latin</th>
                                                    <th class="d-none">Nama Varietas</th>
                                                    <th v-if="detailDataRealisasi.prs_kategori != 'Sumber Daya Genetik'">Bentuk Benih</th>
                                                    <th>Jumlah</th>
                                                    <th>Jumlah Realisasi</th>
                                                    <th>Jumlah Sisa</th>
                                                    <th v-if="detailDataRealisasi.prs_kategori != 'Sumber Daya Genetik'">Satuan</th>
                                                    <th>Nilai (Total Harga dalam USD)</th>
                                                    <th v-if="detailDataRealisasi.prs_kategori != 'Sumber Daya Genetik'">Kode HS</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(value_benih, index_benih) in detailDataRealisasi.permohonan_realisasi_benih" :key="index_benih">
                                                    <td>{{index_benih+1}}</td>
                                                    <td v-if="detailDataRealisasi.prs_kategori != 'Sumber Daya Genetik'">{{value_benih.tanaman.t_nama}}</td>
                                                    <td v-if="detailDataRealisasi.prs_kategori != 'Sumber Daya Genetik'">{{value_benih.prsb_nama_latin}}</td>
                                                    <td class="d-none">{{value_benih.prsb_varietas}}</td>
                                                    <td v-if="detailDataRealisasi.prs_kategori != 'Sumber Daya Genetik'">{{value_benih.bentuk_benih.bb_nama}}</td>
                                                    <td>{{value_benih.prsb_jumlah}}</td>
                                                    <td>{{value_benih.prsb_jumlah_realisasi}}</td>
                                                    <td>{{value_benih.prsb_jumlah_sisa}}</td>
                                                    <td v-if="detailDataRealisasi.prs_kategori != 'Sumber Daya Genetik'">{{value_benih.satuan.s_nama}}</td>
                                                    <td>{{value_benih.prsb_nilai_total}}</td>
                                                    <td v-if="detailDataRealisasi.prs_kategori != 'Sumber Daya Genetik'">{{value_benih.prsb_kode_hs}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </div>
    </div>
</template> 
<script>
    const datalaporan = [];
    export default {
        name: "DetailRealisasiPemohon",
        data() {
            return {
                idrealisasi: this.$route.params.idrealisasi,
                detailDataRealisasi: datalaporan,
            };
        },
        methods: {
    getFileWithLink(files) {
      let modifiedUrl = files.replace(/^\//, "");
      var win = window.open(this.apiLink + modifiedUrl, "_blank");
      if (win) {
        //Browser has allowed it to be opened
        win.focus();
      } else {
        //Browser has blocked it
        alert("Please allow popups for this website");
      }
    },
        },
        beforeCreate() {
            Swal.fire({
                title: '<i class="fa fa-refresh fa-spin"></i>',
                text: "Mohon tunggu sebentar...",
                showConfirmButton: false,
                allowOutsideClick: false,
            });
            let session_data = JSON.parse(this.session);
            let self = this;
            axios
                .get(this.apiLink + "api/permohonan_realisasi/permohonan_all", {
                    params: {
                        id: this.$route.params.idrealisasi,
                    },
                    headers: {
                        Authorization: "Bearer " + this.access_token,
                    },
                })
                .then((response) => {
                    var res_realisasi = response.data;
                    var res_realisasi_data = res_realisasi.data;
                    console.log(res_realisasi_data);
                    if (res_realisasi.meta.code != "200") {
                        Swal.fire({
                            icon: "error", 
                            title: "Oops...",
                            text: res_realisasi.data.message,
                        });
                    } else {
                        // let raw_realisasi = [];
                        // $.each(res_realisasi_data, function(index_realisasi, val_realisasi) {
                        //      $.each(val_realisasi.permohonan_realisasi_benih, function(index_benih, val_benih) {
                        //         val_benih.realisasi = val_realisasi;
                        //         raw_realisasi.push(val_benih);
                        //      });
                        // });
                        // console.log(raw_realisasi);
                        // var detailData = res_realisasi_data.data.find(detailDatas => {
                        //     if (detailDatas.id === self.idrealisasi) {
                        //         return detailDatas
                        //     }
                        // })
                        // alert(this.idrealisasi);
                        this.detailDataRealisasi = res_realisasi_data.data.data[0];
                        console.log(this.detailDataRealisasi);
                        Swal.close();
                        // this.paging = res_realisasi_data.links;
                        // this.show_data_from = res_realisasi_data.from;
                        // this.show_data_to = res_realisasi_data.to;
                        // this.total_data = res_realisasi_data.total;
                        // this.curent_page = res_realisasi_data.current_page;
                    }
                });
        },
        mounted() {
            $('.no-readonly').prop('readonly', false);
        }
    };
</script>